import { Label } from '@cian/ui-kit';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useIsElectronicTradingEnabled } from 'shared/hooks/useIsElectronicTradingOffer';
import { selectAgentLists } from 'shared/selectors/offerData/selectAgentLists';

export const DepositPropertyLabelContainer: FC = () => {
  const agentLists = useSelector(selectAgentLists);

  /**
   * @todo Удалить RS
   * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
   */
  const isElectronicTradingEnabled = useIsElectronicTradingEnabled();

  const isAvailable =
    /**
     * @todo Удалить RS
     * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
     * Удалить переменную isElectronicTradingEnabled из условия
     */
    isElectronicTradingEnabled && agentLists.includes('pledgeRealty');

  if (!isAvailable) {
    return null;
  }

  return (
    <Label background="gray10_100" color="black_100" data-testid="publisher-labels.deposit-property">
      Залоговая недвижимость
    </Label>
  );
};
