import { IconFullscreen16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FullscreenButton.css';

interface IFullscreenButtonProps {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export function FullscreenButton({ onClick }: IFullscreenButtonProps) {
  return (
    <button data-testid="FullscreenButton" className={styles['fullscreen-button']} onClick={onClick} type="button">
      <IconFullscreen16 color="white_100" />
    </button>
  );
}
