import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getExternalTour } from 'shared/selectors/newObject/getExternalTour';
import { getExternalTourBannerImageUrl } from 'shared/selectors/newObject/getExternalTourBannerImageUrl';
import { virtualTourBannerImageUrlSelector } from 'shared/selectors/newObject/getTourBannerImageUrl';

import { track3DTourBlockClick, track3DTourBlockShow } from './tracking';
import { Tour, TourComponent } from '../../components/TourContainer';
import { virtualTourKeySelector } from '../../selectors/newObject/getTour';
import { sendUXSEvent } from '../../utils/uxs';
import { useApplicationContext } from '../ApplicationContext';
import { FullscreenWrapperContainer } from '../FullscreenWrapper';

export function TourContainer() {
  const { config } = useApplicationContext();

  const [open, setOpen] = React.useState(false);
  const tourFrameRef = React.useRef<HTMLIFrameElement>(null);

  const isExternalTourEnabled = !!config.get<boolean>('external_tour_enabled.Enabled');
  const tourKey = useSelector(virtualTourKeySelector);
  const tourBannerImageUrl = useSelector(virtualTourBannerImageUrlSelector);
  const externalTourKey = useSelector(getExternalTour);
  const externalTourBannerImageUrl = useSelector(getExternalTourBannerImageUrl);

  const tourLink = React.useMemo(() => {
    return isExternalTourEnabled ? tourKey || externalTourKey : tourKey;
  }, [tourKey, externalTourKey, isExternalTourEnabled]);

  const tourBannerImg = React.useMemo(() => {
    return isExternalTourEnabled ? tourBannerImageUrl || externalTourBannerImageUrl : tourBannerImageUrl;
  }, [externalTourBannerImageUrl, tourBannerImageUrl, isExternalTourEnabled]);

  const tourKeyWithoutFullscreen = React.useMemo(() => {
    if (!tourLink) {
      return '';
    }

    return `${tourLink}${tourLink.includes('?') ? '&' : '?'}fullscreen=no`;
  }, [tourLink]);

  const handleTourShow = React.useCallback(() => {
    setOpen(true);
    track3DTourBlockClick();
    setTimeout(() => {
      sendUXSEvent('novostroiky_3d_desktop');
    }, 3000);
  }, []);

  const handleFullscreenOpen = React.useCallback(() => {
    if (tourFrameRef.current) {
      tourFrameRef.current.focus();
    }
  }, []);

  const handleFullscreenClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  if (!tourLink || !tourBannerImg) {
    return null;
  }

  return (
    <ActionAfterViewed callback={track3DTourBlockShow}>
      <>
        <TourComponent
          tourBannerImageUrl={tourBannerImg}
          onClick={handleTourShow}
          isExternal={Boolean(externalTourKey)}
        />
        {open && (
          <FullscreenWrapperContainer
            isFullScreenInitialState={true}
            onFullscreenOpen={handleFullscreenOpen}
            onFullscreenClose={handleFullscreenClose}
          >
            <Tour frameRef={tourFrameRef} tourUrl={tourKeyWithoutFullscreen} />
          </FullscreenWrapperContainer>
        )}
      </>
    </ActionAfterViewed>
  );
}
